@import "../../styles/colors";

.menu-mobile {
  display: flex;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  svg {
    fill: $light-blue;
  }
}
