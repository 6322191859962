@import "colors";

h1,
h2,
h3,
h4,
.body,
caption,
.caption,
.micro {
  font-family: Inter;
  font-style: normal;
  color: $black;
  &.primary {
    color: $primary-color;
  }
}

h1,
h2,
h3 {
  font-weight: 800;
}

.body,
caption,
.caption,
.micro {
  font-weight: 400;
}

h3,
h4,
.body {
  line-height: 24px;
}

h4,
.body {
  font-size: 1.125em;
}

h1 {
  font-size: 3.5em;
  line-height: 60px; /* 107.143% */
}

h2 {
  font-size: 1.5em;
  line-height: 33px; /* 137.5% */
}

h3 {
  font-size: 1.25em;
}

h4 {
  font-weight: 700;
}

caption,
.caption {
  font-size: 0.875em;
  line-height: 17px;
}

.micro {
  font-size: 0.75em;
  line-height: 18px;
}
