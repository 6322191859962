@import "../../styles/colors";

.card {
  display: flex;
  width: 92.5%;
  justify-content: center;
  align-items: center;
  gap: 32px;

  @media only screen and (max-width: 1224px) {
    flex-wrap: wrap;
    gap: 16px;
  }

  &-item {
    display: flex;
    width: 20%;
    padding: 16px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    flex-shrink: 0;
    border-radius: 8px;
    @media only screen and (max-width: 1224px) {
      width: 40%;
    }

    &-icon {
      width: 64px;
      height: 64px;
    }

    &-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 12px;

      h4 {
        color: $white;
        text-align: center;
        margin: 0;
      }

      .body {
        color: $white;
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 133.333% */
        letter-spacing: -0.54px;
      }
    }
  }
}
