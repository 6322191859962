@import "../../styles/typography";
@import "../../styles/colors";

.menu {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
  border-radius: 6px;
  a {
    text-decoration: none;
    color: $primary-color;
    &:hover, &.selected {
      color: $light-blue;
    }
  }
}
