@use "../../styles/colors";

.header {
  display: flex;
  padding: 32px 48px;
  justify-content: space-between;
  align-items: center;

  .logo-wrapper {
    width: 250px;
    height: 35.425px;
    flex-shrink: 0;
    img {
      width: 100%;
    }
  }
  
}
