@import "../../styles/colors";

.footer {
  display: flex;
  padding: 80px 48px 64px 48px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  background: $dark-blue;

  &-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    @media only screen and (max-width: 1224px) {
      justify-content: flex-start;
    }

    img {
      width: 13em;
    }
  }

  &-stack {
    @media only screen and (max-width: 1224px) {
      align-self: flex-start;
    }
  }

  &-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    a {
      text-decoration: none;
      text-align: center;
      color: $white;
      @media only screen and (max-width: 1224px) {
        text-align: left;
        align-self: flex-start;
        font-size: 14px;
      }
    }
  }

  &-copyright p {
    margin-top: 32px;
    color: $white;
    text-align: center;
    font-feature-settings: "calt" off;
    font-family: Inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 16.5px */
    letter-spacing: -0.11px;
    @media only screen and (max-width: 1224px) {
      font-size: 11px;
    }
  }
}
