// Colors
$blue:#164AB4;
$dark-blue: #05205D;
$light-blue: #48A6DC;
$green: #7FD5CA;
$yellow: #F8D15D;
$black: #000000;
$dark-grey: #404040;
$grey: #808080;
$light-grey: #DDDDDD;
$white: #FFFFFF;


$primary-color: $blue;
$primary-color-light: $light-blue;
$primary-color-dark: $dark-blue;

