@import "../../styles/colors";

.app-carousel {
  width: 683px;
  .carousel {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }

  @media only screen and (max-width: 1224px) {
    width: 330px;
  }

  .arrowNext, .arrowPrev {
    width: 25px;
  }
  .arrowPrev:hover, .arrowNext:hover {
    cursor: pointer;
  }
  .carousel-item {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 15px;

    .carousel-content {
      display: flex;
      width: 576px;
      align-items: center;
      border-radius: 8px;
      background: $white;
      gap: 12px;

      box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);

      @media only screen and (max-width: 1224px) {
        width: 228px;
        flex-direction: column;
      }

      &.unique {
        @media only screen and (max-width: 1224px) {
          width: 296px;
        }
        &-img {
          @media only screen and (max-width: 1224px) {
            height: 105px;
          }
        }
      }

      &-img {
        width: 178px;
        min-height: 408px;
        flex-shrink: 0;
        border-radius: 8px 0px 0px 8px;
        @media only screen and (max-width: 1224px) {
          border-radius: 8px 8px 0px 0px;
          min-height: 160px;
          width: 100%;
        }
      }

      &-body {
        display: flex;
        width: 398px;
        min-height: 344px;
        padding: 32px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 32px;
        flex-shrink: 0;

        @media only screen and (max-width: 1224px) {
          padding: 0px 16px 16px 16px;
          width: 100%;
          align-items: center;
          gap: 32px;
        }

        &-txt {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 16px;

          h4 {
            color: $dark-blue;
            margin: 0;
          }

          p.caption {
            margin: 0;
            color: $dark-blue;
            @media only screen and (max-width: 1224px) {
              text-align: left;
            }
          }
        }

        &-cta {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 8px;

          .button-text {
            @media only screen and (max-width: 1224px) {
              font-size: 17px;
            }
          }

          a {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
            text-decoration-line: underline;
            width: 200px;
            color: $dark-blue;
          }
        }
      }
    }
  }
}
