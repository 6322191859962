@import "../../styles/colors";

.layout {
  .layout-drawer {
    top: 100px !important;
    box-shadow: none !important;
    display: flex;
    padding: 32px;
    flex-direction: column;
    align-items: flex-end;
    gap: 32px;
    flex-shrink: 0;

    .body {
      margin: 0;
    }

    a {
      text-decoration: none;
      color: $dark-blue;
      &:hover, &.selected {
        color: $light-blue;
      }
    }
  }

  .EZDrawer__overlay {
    top: 110px !important;
    background: none !important;
  }
}
