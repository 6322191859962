@import "../../styles/colors";

.button {
  display: inline-flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 24px;
  border: 0;
  color: $white;
  &-icon {
    width: 23.939px;
    height: 24px;
  }
  &-text {
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
  }
  &:hover {
    cursor: pointer;
  }
  &:active {
    padding: 6px 22px;
    border: 2px solid;
  }

  &.blue {
    background-color: $blue;
    svg {
      fill: $light-blue;
     }
  }

  &.light-blue {
    background-color: $light-blue;
    svg {
      fill: $white;
     }

    &:active {
      background-color: $white;
      color: $light-blue;
      border-color: $light-blue;
      svg {
        fill: $light-blue;
       }
    }
  }

  &.dark-blue {
    background-color: $dark-blue;
    svg {
      fill: $light-blue;
     }
    &:active {
      background-color: $white;
      color: $dark-blue;
      border-color: $dark-blue;
      svg {
        fill: $dark-blue;
       }
    }
  }

  &.grey {
    background-color: $grey;
  }

  &.white {
    background-color: $white;
  }
}
