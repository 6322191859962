@import "variables";
@import "colors";
@import "typography";

body {
  margin: 0 auto;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.hero {
  display: flex;
  padding: 80px 48px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 128px;
  background: linear-gradient(
      90deg,
      rgba(5, 32, 93, 0.4) 24.43%,
      rgba(5, 32, 93, 0) 61.76%
    ),
    linear-gradient(0deg, rgba(5, 32, 93, 0.5) 0%, rgba(5, 32, 93, 0.5) 100%),
    url("../images/HERO-min.png") lightgray 0px -145.097px / 100% 168.85% no-repeat;
  background-size: cover;

  @media only screen and (max-width: 1224px) {
    padding: 64px 32px;
    align-items: center;
    gap: 64px;
    background: linear-gradient(
        180deg,
        rgba(5, 32, 93, 0) 68.89%,
        rgba(5, 32, 93, 0.4) 81.78%
      ),
      linear-gradient(0deg, rgba(5, 32, 93, 0.5) 0%, rgba(5, 32, 93, 0.5) 100%),
      url("../images/HERO-min.png") lightgray -227.436px 0px / 241.03% 100% no-repeat;
  }
  h1,
  h3,
  .caption {
    color: $white;
    text-align: center;
  }
  &-txt {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    @media only screen and (max-width: 1224px) {
      align-items: center;
      align-self: stretch;
    }
  }

  &-cta {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    @media only screen and (max-width: 1224px) {
      gap: 4px;
      align-self: stretch;
    }
  }
}

.diferenciais {
  display: flex;
  padding: 80px 48px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  background: $blue;
  @media only screen and (max-width: 1224px) {
    padding: 64px 32px;
    align-items: center;
  }
  h2 {
    color: $white;
  }
}

.sobre {
  display: flex;
  padding: 80px 48px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 64px;

  @media only screen and (max-width: 1224px) {
    padding: 64px 32px;
  }

  &-intro {
    display: flex;
    align-items: center;
    gap: 32px;
    width: 100%;

    @media only screen and (max-width: 1224px) {
      flex-direction: column;
      justify-content: center;
      gap: 24px;
    }
    &-img {
      flex: 1 1 0px;
    }

    img {
      max-width: 100%;
    }
    &-text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      flex: 1 1 0px;

      img {
        width: 18.5em;
        @media only screen and (max-width: 1224px) {
          width: 100%;
        }
      }

      p {
        color: $dark-blue;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin: 0;
      }
    }
  }

  &-especialidades {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    @media only screen and (max-width: 1224px) {
      padding: 0px 32px;
    }

    > h2 {
      color: $light-blue;
    }

    &-cards {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      @media only screen and (max-width: 1224px) {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 16px;
      }

      &-item {
        display: flex;
        width: 211.2px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 32px;
        flex-shrink: 0;
        @media only screen and (max-width: 1224px) {
          flex-direction: row;
          justify-content: flex-start;
          width: 100%;
          gap: 16px;
        }
        img {
          width: 100%;
          border-radius: 8px;
          @media only screen and (max-width: 1224px) {
            width: 88px;
            height: 64px;
            flex-shrink: 0;
            border-radius: 8px;
          }
        }
        h4 {
          color: $dark-blue;
          margin: 0;
        }
      }
    }
  }

  &-duvidas {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;

    &-frame {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 24px;

      &-txt {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        text-align: center;

        h3,
        p {
          margin: 0;
          color: $dark-blue;
        }
      }
    }

    &-body {
      margin: 0;
      color: $dark-blue;
      @media only screen and (max-width: 1224px) {
        text-align: center;
        font-size: 18px;
      }
    }
  }
}

.agendamento {
  display: flex;
  padding: 80px 48px;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  background: $light-blue;

  @media only screen and (max-width: 1224px) {
    padding: 64px 32px 0px 32px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }
  &-especialidades {
    display: flex;
    width: 33%;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    flex-shrink: 0;
    color: $white;
    @media only screen and (max-width: 1224px) {
      width: 100%;
      padding: 0px 32px;
    }
    h2,
    p {
      margin: 0;
      color: $white;
    }

    p {
      font-size: 18px;
    }

    &-cta {
      display: flex;
      padding-left: 32px;
      align-items: flex-end;
      gap: 4px;

      &-frame {
        display: flex;
        padding: 32px 0px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 12px;

        a {
          width: 130px;

          &.apple {
            width: 140px;
          }
          img {
            width: 100%;
          }
        }

        p {
          text-align: center;
        }
      }

      &-image {
        width: 150px;
        height: 297.222px;
        flex-shrink: 0;
        background: url("../images/celular.png") 50% / cover no-repeat;
      }
    }
  }
  > img {
    max-width: 66%;
  }
}

.unidades {
  display: flex;
  padding: 80px 48px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 64px;

  @media only screen and (max-width: 1224px) {
    padding: 64px 32px;
  }

  .carousel-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-duvidas {
    display: flex;
    width: 100%;
    padding: 0px 32px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;

    h4 {
      color: $dark-blue;
      margin: 0;
    }

    &-contatos {
      svg {
        fill: $light-blue;
      }

      &-item {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 12px;
        cursor: pointer;

        svg {
          width: 26px;
          height: 20.656px;
        }
      }

      p {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        color: $dark-blue;
      }
    }
  }
}
