.App-link {
  color: #61dafb;
}

.App {
  text-align: center;
  overflow: hidden;

  &-header {
    min-height: 30vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: 600px)  {
      min-height: 18vh;
    }
    @media only screen and (min-width: 601px) and (max-width: 1366px)  {
      min-height: 20vh;
    }
  }
  &-body {
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: #33254b;
    h1 {
      font-size: 1.6em;
    }
    h2 {
      font-size: 1.2em;
    }
    @media only screen and (max-width: 600px)  {
      min-height: 74.5vh;
    }
    @media only screen and (min-width: 601px) and (max-width: 1366px)  {
      min-height: 67vh;
    }
  }
  &-footer {
    min-height: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    font-size: calc(10px + 2vmin);
    @media only screen and (max-width: 600px)  {
      min-height: 7.4vh;
    }
    @media only screen and (min-width: 601px) and (max-width: 1366px)  {
      min-height: 13vh;
    }
  }
  &-logo {
    pointer-events: none;
    max-height: 20vh;
    &-wrapper {
      padding-top: 6vh ;
    }
    @media only screen and (max-width: 290px)  {
      max-width: 50vh;
    }
    @media only screen and (min-width: 291px) and (max-width: 600px)  {
      max-width: 59vh;
    }
    @media only screen and (min-width: 601px) and (max-width: 1366px)  {
      max-height: 67vh;
    }
  }

  .img-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    .img-wrapper {
      width: 100%;
    }
    a {
      padding: 0 30px;

      max-height: 96px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media only screen and (max-width: 600px)  {
        width: 45vw;
        padding: 0;
        margin-bottom: 10px;
      }
      img.brand {
        max-height: 90px;
        max-width: 100%;
        &.amil {
          max-height: 78px;
        }
        &.es {
          max-height: 66px;
        }
        &.cia {
          margin-left: -30px;
          @media only screen and (max-width: 600px)  {
            margin-left: -5px;
          }
        }
      }

      img.social {
        height: 42px;
      }
    }
  }
}
